<template>
    <aside class="slider mainSlider" v-show="data">
        <ul class="content">
            <li v-for="(item, index) in data" :key="index" class="cover-bg" :style="{ backgroundImage: `url(${item.imagePath})` }">
                <!-- <li v-for="(item, index) in data" :key="index" class="cover-bg" :style="{'background-image': 'url(' + require('@/assets/img/slider.png') + ')'}"></li> -->
            <!-- <li class="cover-bg" :style="{ 'background-image': 'url(' + item.imagePath + ')' }"></li> -->

            <li>
                <picture><img src="@/assets/img/slider.png" alt="" /></picture>
                <!-- <picture><img :src="slider[0].imagePath" alt="Slider" /></picture> -->
            </li>
        </ul>
        <ul v-if="data.length > 1" class="nav fade fadein fadeinstart">
            <li v-for="(item, index) in data" :key="index">
                <button :class="[index == 0 ? 'active' : '']"><span class="visuallyhidden">(Aktywny slajd)</span></button>
            </li>
        </ul>
    </aside>
</template>
<script>
import $ from "jquery";

export default {
    name: "Slider-section",
    props: ["data"],
    methods: {
        sliderRun() {
            $('.slider').zSlider({
                speed : 5000,
                duration : 800,
                startDelay: 400
            });
        }
    },
    updated() {
        this.sliderRun();
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.slider {
    position: relative;
    width: 100%;
    background-color: $colorWhite;
    &.mainSlider {
        .content {
            max-height: calc(100vh - 140px);
            @media screen and (max-width: $smalltablet) {
                max-height: 50vh;
            }
            @media screen and (max-height: 500px) {
                min-height: 300px !important;
            }
        }
    }
    .content {
        li {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            display: none;
            width: 100%;
            height: 100%;
            .sliderLogo {
                background: url(../assets/img/svg/logoslider.svg) center center no-repeat;
                background-size: 400px auto;
                width: 400px;
                height: 100%;
            }
            .container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                h2 {
                    display: flex;
                    color: $colorWhite;
                    font-weight: 400;
                    align-items: center;
                    text-align: center;
                }
            }
            // &:nth-child(1) {
            // display: flex;
            // }
            // &:last-of-type {
            //     visibility: hidden;
            // }
        }
    }

    .nav {
        position: absolute;
        z-index: 9;
        width: 100%;
        height: 36px;
        left: 0;
        bottom: -18px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        li {
            margin: 0 4px;
            display: inline-block;
            button {
                @include button;
                background: $colorGray;
                display: block;
                width: 18px;
                height: 18px;
                z-index: 1;
                cursor: pointer;
                transition: all 0.3s ease;
                position: relative;

                &.active,
                &:hover {
                    background: $colorYellow;
                }
            }
        }
    }
}

</style>
