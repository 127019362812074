<template>
    <article v-if="daneDoWyswietlenia" class="halfWide">
        <div class="halfWide__left halfWide__left--img">
            <!-- <picture :style="{ backgroundImage: fullSectionImagePath }"><img :src="daneDoWyswietlenia.imagePath" alt="" /> </picture> -->
            <picture
                :style="{
                    'background-image':
                        'url(' + require('@/assets/img/image.png') + ')',
                }"
                ><img src="@/assets/img/image.png" alt="" />
            </picture>
        </div>
        <div class="halfWide__right halfWide__right--text">
            <div
                class="fade fadeinright"
                v-html="daneDoWyswietlenia.content"
            ></div>
        </div>
    </article>
</template>

<script>
// import axios from "axios";
export default {
    data() {
        return {
            sectionAbout: [],
            bright: "",
        };
    },
    name: "Sekcja-pol-na-pol",
    props: ["offer", "daneDoWyswietlenia"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

header {
    h2,
    h3 {
        color: $colorWhite;
    }
}
.halfWide {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 600px;
    @media screen and (max-width: $tablet) {
        min-height: auto;
    }
    &:nth-of-type(2n + 2) {
        flex-direction: row-reverse;
    }
    &.bright {
        .halfWide__right {
            background: $colorWhiteF7;
            text-align: left;
            header {
                h2,
                h3 {
                    color: $colorDark;
                    text-transform: uppercase;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }
            p {
                color: $colorDark;
            }
            a {
                display: block;
                @include fontSize22px;
            }
        }
    }
    &.noheader {
        .medium {
            h2 {
                display: none;
            }
        }
    }
    &.darkbg {
        span,
        .subheader {
            color: $colorWhite;
        }
    }
    &__left {
        // width: 50%;
        height: auto;
        position: relative;
        picture {
            background-size: cover;
            background-position: 50%;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: relative;
            img {
                width: 100%;
                height: auto;
                @media screen and (min-width: $tablet) {
                    display: none;
                }
            }
        }
    }
    &__right {
        max-width: 50vw;
        height: auto;

        background: $colorF5;
        // text-align: center;
        display: flex;
        // align-items: center;
        justify-content: center;
        flex-direction: column;
        & > div {
            @include padding100;
            padding-left: 100px;
            max-width: 600px;
        }
        ul {
            margin: 0;
            list-style: none;
            li {
                &:before {
                    content: "\2022";
                    color: $colorMain;
                    position: relative;
                    left: 0px;
                    top: -2px;
                    padding-right: 8px;
                    font-size: 14px;
                }
            }
            // li {
            // color: $colorWhiteF7;
            // }
        }
        &--text {
            @include fontSize18px;
        }
    }
    @media screen and (max-width: $tablet) {
        flex-direction: column;
        &:nth-of-type(2n + 2) {
            flex-direction: column;
        }
        &__right {
            max-width: none;
            & > div {
                max-width: 100%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
    &__right {
        width: 100%;
    }
    &__left {
        width: 100%;
    }

}
</style>
