import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        //tutaj się dodają routy, z APP za pomocą addRoute. Robimy to tam, ponieważ w komponencie Routera nie powinniśym nic pobierać.
    ],
    scrollBehavior(to, from, savedPosition) {
        return { y: 0 };
         // if (to.fullPath) {
        //     return {
        //       el: to.fullPath,
        //       behavior: 'smooth',
        //     }
        //   }
        // return { y: 0 };
        // return savedPosition || new Promise((resolve)=> {
        //     setTimeout(()=> resolve({ y: 0, behavior: 'smooth' }), 300)
        // });
    },
})


const DEFAULT_TITLE = 'PS Technic';
router.afterEach((to, from) => {
    document.title = DEFAULT_TITLE;
    // document.title = to.meta.title + " - PS Technic" || DEFAULT_TITLE;
});

export default router
