<template>
    <div>
        <header class="topbar">
            <div class="container topbar-container">
                <h2 class="topbar__left">
                    <router-link data-hash="home" to="/" @click="menuClickHandler"
                        ><picture
                            ><img
                                src="@/assets/img/svg/main-logo.svg"
                                alt="PS Technic"
                                title="PS Technic" /></picture
                    ></router-link>
                </h2>
                <!-- v-on:click="showSubmenu" -->
                <div class="topbar__right">
                    <nav class="navbar zMenu">
                        <ul>
                            <li
                                v-for="(item, index) in menu"
                                :key="index"
                                :class="{
                                    subMenu: item.galaz && item.rozwin == 1,
                                }"
                            >
                                <a
                                    :href="`${item.href}`"
                                    v-if="item.href != ''"
                                    @click="menuClickHandler"
                                    >{{ item.name }}</a>

                                <router-link v-else :to="{ name: 'home', hash: `/${item.href}`}">{{ item.name }}</router-link>
                                <!-- <router-link
                                    v-else
                                    :to="``"
                                    :class="{ noclick: item.noclick == 1 }"
                                    >{{ item.name }}</router-link> -->

                                <button
                                    v-if="item.galaz && item.rozwin == 1"
                                    class="showButton"
                                    data-wcag="1"
                                    aria-label="Rozwiń"
                                >
                                    <span style="display: none">Rozwiń</span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div class="language">
                        <!-- <router-link
                            :to="`/${oppositeHrefLanguage}`"
                            :class="[oppositeNameLanguage]"
                            v-on:click.native="changeLanguage"
                            >{{ oppositeNameLanguage }}</router-link
                        > -->
                        <a
                            :href="`/${oppositeHrefLanguage}`"
                            :title="`Zmień język na ${oppositeNameLanguage}`"
                            :class="[oppositeNameLanguage]"
                            @click.native="changeLanguage"
                            >{{ oppositeNameLanguage }}</a
                        >
                        <!-- <button v-if="oppositeNameLanguage == 'en'" :class="[oppositeNameLanguage]" @click="changeLanguage">{{ oppositeNameLanguage }}</button>
                        <button v-else :class="[oppositeNameLanguage]" @click="changeLanguage">{{ oppositeNameLanguage }}</button> -->
                    </div>
                    <button
                        class="burgermenu"
                        type="button"
                        title="Kliknij, by otworzyć menu"
                    ></button>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import axios from "axios";
import zMenu from "@/assets/js/zdzislowicz-burger-1.8";
import EventBus from "@/components/event-bus/event-bus";

export default {
    data() {
        return {
            menu: [],
            activateLanguage: this.$root.language,
            oppositeHrefLanguage: this.$root.oppositeHrefLanguage,
            oppositeNameLanguage: this.$root.oppositeNameLanguage,
        };
    },
    name: "TopBar",
    computed: {},
    methods: {
        changeLanguage() {
            if (this.activateLanguage == "en") {
                this.activateLanguage = "pl";
                this.oppositeHrefLanguage = "en/";
                this.oppositeNameLanguage = "en";
                EventBus.$emit('zmienJezyk', this.activateLanguage)
            } else {
                this.activateLanguage = "en";
                this.oppositeHrefLanguage = "";
                this.oppositeNameLanguage = "pl";
                EventBus.$emit('zmienJezyk', this.activateLanguage)
            }
            this.getMenu();
        },
        
        menuClickHandler(e) {
            e.preventDefault();
            // get from menu
            let linkHash = e.target.hash?.replace("#", "");

            if (!linkHash) {
                // if not exist AND event target is image :(
                linkHash = e.target.closest("a").dataset.hash;
            }

            const el = document.getElementById(linkHash);
            if (el) {
                window.scrollTo({
                    top: el.offsetTop,
                    behavior: "smooth",
                });
            }
        },

        getMenu() {
            axios
                .get(
                    "https://ps-technic.pl/api/menu/menu.php?jezyk=" +
                        this.activateLanguage
                )
                .then((response) => {
                    this.menu = response.data;
                });
        },
    },
    mounted() {
        this.getMenu();
    },
    updated() {
        zMenu.zMenuRun();
        console.log('activateLanguage')
        console.log(this.activateLanguage)
        console.log('root')
        console.log(this.$root.language)
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

// .burgermenu {
//     @include button;
//     background: url(../assets/img/svg/hamburger-20x20.svg) center center
//         no-repeat;
//     background-size: 32px auto;
//     width: 44px;
//     height: 44px;
//     right: -7px;
//     position: relative;
//     // margin-left: 20px;

//     @media screen and (min-width: 1023px) {
//         display: none;
//     }
// }

.topbar {
    //height: 120px;
    color: $colorWhite;
    position: fixed;
    z-index: 4;
    margin: 0 auto;
    height: auto;
    background-color: $colorMain;
    &-container {
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // flex-wrap: wrap;
        @media screen and (max-width: 1023px) {
            // width: 746px;
            height: 70px;
        }
    }
    &__left {
        position: relative;
        padding: 0;

        picture {
            img {
                width: auto;
                height: 46px;
                @media screen and (max-width: $smalltablet) {
                    height: 40px;
                }
            }
        }
    }
    &__right {
        display: flex;
        align-items: center;
        gap: 40px;
        @media screen and (max-width: $smalltablet) {
            gap: 18px;
        }
    }
    .language {
        // :first-child {
        //     display: none;
        // }
        button,
        a {
            @include button;
            height: 50px;
            transition: 0.2s ease-in background-color;
            display: flex;
            align-content: center;
            justify-content: center;
            line-height: 0.8;
            text-transform: uppercase;
            @include fontSize18px;
            padding: 18px 45px 16px 18px;
            color: $colorWhite;
            min-width: 86px;
            &.pl {
                background: url(../assets/img/svg/pl-flag.svg) right 15px center
                    no-repeat rgba(0, 0, 0, 0.1);
                background-size: 21px auto;
            }
            &.en {
                background: url(../assets/img/svg/en-flag.svg) right 15px center
                    no-repeat rgba(0, 0, 0, 0.1);
                background-size: 21px auto;
            }
            &:hover {
                background-color: rgba(0, 0, 0, 0.3);
            }
            span {
            }
            @media screen and (max-width: $smalltablet) {
                height: 40px;
                padding: 12px 40px 10px 10px;
                &.pl {
                    background: url(../assets/img/svg/pl-flag.svg) right 15px
                        center no-repeat rgba(0, 0, 0, 0.1);
                    background-size: 19px auto;
                }
                &.en {
                    background: url(../assets/img/svg/en-flag.svg) right 15px
                        center no-repeat rgba(0, 0, 0, 0.1);
                    background-size: 19px auto;
                }
                span {
                }
            }
            @media screen and (max-width: $mobile) {
                line-height: 1.4;
            }
        }
    }
    .burgermenu {
        @include button;
        background: url(../assets/img/svg/hamburger-20x20.svg) center center
            no-repeat;
        background-size: 32px auto;
        width: 32px;
        height: 32px;
        z-index: 99;
        // margin-left: 20px;

        @media screen and (min-width: 1023px) {
            display: none;
        }
    }

    .navbar {
        z-index: 99;
        position: relative;
        width: 100%;
        padding-left: 100px;
        // padding: 0 40px 0 45px;
        ul {
            width: 100%;
            display: flex;
            z-index: 2;
            align-items: center;
            justify-content: flex-end;
            position: relative;
        }
        & > ul {
            gap: 38px;
            height: 100px;
        }
        .showsub {
            display: flex;
        }
        li {
            height: 100%;
            display: flex;
            z-index: 2;
            position: relative;
            align-items: center;
            a {
                padding: 0;
                font-weight: 400;
                @include fontSize18px;
                display: flex;
                align-items: center;
                position: relative;
                transition: 0.3s ease all;
            }
            & > a {
                height: 100%;
                display: flex;
                align-items: center;
            }
            &:last-of-type {
                a {
                    margin-right: 0;
                }
            }
            button {
                height: 100px;
                width: 22px;
                padding: 0;
                background: url(../assets/img/svg/icon-arrow-bottom.svg) right
                    center no-repeat transparent;
                background-size: 70% auto;
                cursor: pointer;
                border: none;
            }

            ul {
                top: 80px;
                left: -30px;
                width: fit-content;
                min-width: 114px;
                padding: 25px;
                z-index: 5;
                align-items: flex-start;
                flex-direction: column;
                position: absolute;
                display: none;
                z-index: 99;
                li {
                    margin-right: 0;
                    white-space: nowrap;
                    display: flex;
                    z-index: 5;
                    opacity: 1;
                    &:hover {
                        text-decoration: none;
                    }
                    button {
                        height: 30px;
                        width: 30px;
                        background: url(../assets/img/svg/icon-arrow-bottom.svg)
                            right center no-repeat;
                        background-size: 70% auto;
                        display: inline;
                    }
                    a {
                        line-height: 30px;
                        width: 100%;
                        text-transform: initial;
                        display: block;
                    }
                    ul {
                        // position: relative;
                        position: absolute;
                        left: 100%;
                        padding: 25px 30px;
                        top: 0;
                        z-index: 99;
                        &.showButton {
                            position: absolute;
                        }
                    }
                    // &:last-of-type {
                    //     display: block !important;
                    // }
                }
            }
        }

        //NAVBAR-COLORS & SIZES
        li a {
            color: $colorWhite;
        }
        // li .active {
        // color: $green;
        // text-decoration: underline;
        // }
        li ul {
            background: $colorGray;
        }
        // ul > li.active > a {
        //     color: $text;
        // }
        ul > li a:hover {
            color: $colorGreye4;
        }
        // ul > li.active > button {
        //     color: black;
        // }
    }
}

.noclick {
    cursor: default;
}
</style>
