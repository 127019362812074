<template>
    <div id="app">
        <div class="preloader-container" v-show="!glownaIsReady && !textPages[11]">
            <div class="lds-dual-ring"></div>
        </div>
        <TopBar v-show="glownaIsReady || textPages[11]" @zmienJezyk="(value) => { handleEvent = value }"></TopBar>
        <main v-show="glownaIsReady || textPages[11]" class="main" id="main-section">
            <router-view :stronyZApp="strony"/>
        </main>
        <Footer v-show="glownaIsReady || textPages[11]"></Footer>
    </div>
</template>

<script>
import axios from "axios";
import TopBar from "@/components/TopBar";

import Glowna from "@/pages/Glowna.vue";
import Tekstowa from "@/pages/Tekstowa.vue";
import Kontakt from "@/pages/Kontakt.vue";
import NotFound from "@/pages/NotFound.vue";

import Footer from "@/components/Footer";
//js

import EventBus from "@/components/event-bus/event-bus";

import "./assets/js/admin";
import "./assets/js/zdzislowicz-burger-1.8";
import "./assets/js/zdzislowicz-animations-1.2.js";
import "./assets/js/zdzislowicz-contact-1.5";
import "./assets/js/zdzislowicz-slider-1.7.js";
import "./assets/js/zdzislowicz-cookies-1.1";

// css
import "./assets/css/magnific.min.css";
import "./assets/css/zdzislowicz-animations-1.1.min.css";

export default {
    name: "PsTechnic",
    data() {
        return {
            textPages: [],
            glownaIsReady: false,
            zAnimationsReady: false,
            activateLanguage: this.$root.language,
            oppositeHrefLanguage: this.$root.oppositeHrefLanguage,
            oppositeNameLanguage: this.$root.oppositeNameLanguage,
        };
    },
    components: {
        TopBar: TopBar,
        Footer: Footer,
    },
    props: ["pageData", "wszystkieStrony"],
    computed: {
        strony() {
            const content = this.textPages;
            return content;
        },
    },
    methods: {
        addTextPagesRoutes() {
            this.textPages.forEach((x) => {
                let komponent;

                if (x.szablon == "strony/strona-tekstowa") komponent = Tekstowa;
                if (x.szablon == "strony/glowna") komponent = Glowna;
                if (x.szablon == "strony/kontakt") komponent = Kontakt;

                this.$router.addRoute({
                    path: x.link,
                    // name: x.title,
                    jezyk: x.jezyk,
                    gallery: x.gallery,
                    component: komponent,
                    props: { pageData: x, wszystkieStrony: x },
                    meta: {
                        title: x.title,
                    },
                });
            });
            this.$nextTick(() => {
                EventBus.$emit("appComponentReadyEvent", true);
            });
            this.$router.addRoute({
                path: "*",
                component: NotFound,
                meta: {
                    title: "Strony nie znaleziono",
                },
            });
        },
        getTextPages() {
            axios
                .get(
                    "https://ps-technic.pl/api/textPages/textPages.php?jezyk=" +
                        this.activateLanguage
                )
                .then((response) => {
                    this.textPages = response.data;
                    this.addTextPagesRoutes();
                })
                .catch((e) => {
                    EventBus.$emit("appComponentReadyEvent", true);
                });
        },
    },
    beforeCreate() {
        let locale = window.location.pathname.replace(
            /^\/([^\/]+).*/i,
            "$1"
        );

        if (window.location.href.indexOf("en") <= -1) {
            locale = "pl";
            this.$root.oppositeHrefLanguage = "en/";    
            this.$root.oppositeNameLanguage = "en";    
        }     
        else {
            this.$root.oppositeHrefLanguage = "";
            this.$root.oppositeNameLanguage = "pl";    
        }
            
        this.$root.language = locale;

    },
    created() {
        EventBus.$on('zmienJezyk', (value) => {
            this.activateLanguage = value
        })
    },
    mounted() {
        EventBus.$on("allComponentsReady", (value) => {
            this.glownaIsReady = value;
            this.zAnimationsReady = value;
        });
        this.getTextPages();
    },
    beforeUpdate() {
        EventBus.$on('zmienJezyk', (value) => {
            this.activateLanguage = value
            this.getTextPages();
        })
    }
};
</script>

<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.preloader-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    z-index: 99;
    top: 0;
    left: 0;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.subheader {
    text-align: center;
    color: $menu;
    font-style: italic;
    font-weight: 400;
    padding-bottom: 0;
    @include fontSize24px;
}
// .container {
//     text-align: center;
// }

.subtitle {
    background: $colorWhite;
    @include padding50;
}

.big {
    h2 {
        @include fontSize45px;
    }
    .subheader {
        @include fontSize24px;
        display: block;
        padding: 10px 0;
    }
}
.medium {
    h2,
    h3 {
        @include fontSize36px;
    }
    .subheader {
        @include fontSize14px;
        display: block;
        padding: 10px 0;
    }
}
.normal {
    h2,
    h3 {
        @include fontSize24px;
    }
    .subheader {
        @include fontSize13px;
        display: block;
        padding: 10px 0;
    }
}
.small {
    h2,
    h3 {
        @include fontSize20px;
        padding-bottom: 10px;
    }
    .subheader {
        @include fontSize13px;
        display: block;
        padding: 10px 0;
    }
}

.subpage-header {
    margin-bottom: 80px;
    h1 {
        max-width: 1200px;
        margin: 0 auto;
        line-height: 1.4;
    }
}

.bodynoscroll {
    overflow-y: hidden;
}

.bodyscroll {
    overflow-y: scroll;
}

picture {
    width: 100%;
    height: auto;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
}

body {
    font-family: $fontDMSans;
    color: $colorGray;
    line-height: 1.4;
    @include fontSize14px;
    font-weight: 400;
    background-color: $colorWhite;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    display: grid;
    -ms-grid-rows: auto 1fr auto;
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    a {
        font-family: $fontDMSans;
        color: $colorGray;
        // @media screen and (max-width: $smalltablet) {
        //     font-size: 17px !important;
        // }
        // @media screen and (max-width: $mobile) {
        //     font-size: 14px !important;
        // }
    }
    p {
        font-weight: 400;
        color: $colorGray;
        // @media screen and (max-width: $smalltablet) {
        //     font-size: 17px !important;
        // }
        // @media screen and (max-width: $mobile) {
        //     font-size: 14px !important;
        // }
    }
    span {
        color: $colorGray;
    }
}
.hide {
    display: none;
}
main {
    overflow: hidden;
    font-family: $fontDMSans;
    font-weight: 400;
    font-style: normal;
    position: relative;
    background-color: $colorWhite;
    top: 100px;
    // padding-top: 120px;
    // @media screen and (max-width: 1419px) {
    //     padding-top: 106px;
    // }
    ul {
        padding: 0;
        // list-style: none;
    }
    @media screen and (max-width: $tablet) {
        top: 70px;
    }
}

b {
    font-family: $fontDMSans;
}

strong {
    font-family: $fontDMSans;
}

h1 {
    font-family: $fontDMSans;
    line-height: 1;
}
h2 {
    font-family: $fontDMSans;
    @include fontSize24px;
    color: $colorGray;
    font-weight: 700;
    line-height: 1;
    text-align: center;
}
h3 {
    line-height: 1;
    font-family: $fontDMSans;
    color: $colorGray;
    font-weight: 400;
    @include fontSize36px;
}
.headerBright {
    color: $colorWhite;
}

.social {
    width: 90px;
    height: 32px;
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 0 0 35px;
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            a {
                position: absolute;
                display: block;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 32px 32px;
                height: 32px;
                width: 32px;
            }
            &:nth-of-type(1) {
                a {
                    left: 0;
                    top: 0;
                    background-image: url(assets/img/svg/icon-inst.svg);
                }
            }
            &:nth-of-type(2) {
                a {
                    left: 29px;
                    top: 0;
                    background-image: url(assets/img/svg/icon-fb.svg);
                }
            }
            &:nth-of-type(3) {
                a {
                    left: 58px;
                    top: 0;
                    background-image: url(assets/img/svg/icon-yt.svg);
                }
            }
        }
    }
}
.col4 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    // height: 100%;
    .box {
        width: calc((100% - 30px) / 3);
        padding: 60px;
    }
    @media screen and (max-width: $tablet) {
        .colorful {
            min-height: auto;
            padding: 20px;
            flex-direction: column;
        }
    }
    @media screen and (max-width: $mobile) {
        flex-direction: column;
        gap: 15px;
        .colorful {
            padding: 10px 20px;
            width: 100%;
        }
    }
}

.flex3,
.flex4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    article {
        position: relative;
        background: $colorGreyF9;
        .photo {
            // position: relative;
            overflow: hidden;
        }
        a {
            // position: absolute;
            width: 100%;
            height: 100%;
            &:hover picture {
                transform: scale(1.5) rotate(25deg);
            }
            picture {
                width: 100%;
                height: auto;
                position: relative;
                background-size: cover;
                transition: transform 0.3s ease-in-out;
                background-position-x: right;
                img {
                    width: 100%;
                    height: auto;
                    visibility: hidden;
                }
            }
        }
        .text {
            padding: 15px;
            display: flex;
            align-items: center;
            flex-direction: column;
            h3 {
                @include fontSize24px;
                text-transform: uppercase;
                font-weight: 300;
            }
            .goTo {
                margin: 0 auto;
                background: $colorDark;
                color: $colorWhite;
                padding: 20px;
                transition: 0.3s ease-in all;
                width: fit-content;
                margin-top: 15px;
                &:hover {
                    color: $colorDark;
                    background-color: $colorYellow;
                }
            }
        }
    }
}
.flex3 {
    article {
        width: calc((100% - 60px) / 3);
    }
}
.flex4 {
    article {
        width: calc((100% - 90px) / 4);
    }
}
.events {
    &.spacer {
        @include padding100Bottom;
    }
    &.people {
        h3 {
            text-align: center;
            font-weight: 300;
            @include fontSize24px;
            white-space: pre-line;
            padding: 0;
        }
    }
    .archive {
        @include padding40Top;
    }
    h3 {
        @include fontSize24px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: left;
    }
    .event {
        position: relative;
        overflow: hidden;
        &:hover {
            .picture {
                .overlay {
                    opacity: 1;
                    top: 0;
                }
            }
            a > header {
                visibility: hidden;
            }
        }
        p {
            @include fontSize14px;
        }
        span {
            @include fontSize16px;
            font-style: italic;
            display: block;
        }
        a {
            width: 100%;
            z-index: 9;
            &:hover {
                p {
                    top: 50%;
                }
            }
        }
        .picture {
            position: relative;
            overflow: hidden;
            margin-bottom: 15px;
            .overlay {
                content: "";
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-color: rgba($color: $colorGrey3C, $alpha: 0.6);
                width: 100%;
                height: 100%;
                padding: 15px;
                mix-blend-mode: hard-light;
                z-index: 99;
                transition: 0.3s ease;
                top: 80px;
                bottom: 0;
                opacity: 0;
                left: 0;
                header {
                    h2 {
                        color: $colorWhite;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                .social {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    width: 100%;
                    margin: 0;
                }
                a {
                    position: relative;
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 28px 28px;
                    height: 28px;
                    width: 28px;
                    &.insta {
                        background-image: url(@/assets/img/svg/icon-inst.svg);
                    }
                    &.fb {
                        background-image: url(@/assets/img/svg/icon-fb.svg);
                    }
                    &.linkedin {
                        background-image: url(@/assets/img/svg/icon-yt.svg);
                    }
                }
            }
            picture {
                background-color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                // img {
                //     max-height: 340px;
                //     max-width: 380px;
                // }
            }
        }
    }
}

.textContent {
    h3 {
        @include fontSize20px;
    }
    p {
        display: block;
        width: 100%;
    }
    p > img {
        display: inline;
    }
    p {
        a {
            color: #ab1456;
            transition: 0.3s ease-in all;
            &:hover {
                color: $colorDark;
            }
        }
        &.element__item {
            @media screen and (max-width: $smalltablet) {
                img {
                    width: 100% !important;
                    float: none !important;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    margin-left: 0 !important;
                    margin-right: 0 !important;
                }
            }
        }
    }
}

.navbarMobile {
    background-color: $colorWhite;
    height: 100vh;
    overflow-y: auto;
    overflow-y: auto;
    z-index: 10000000;
    right: auto;
    left: 100vw;
    padding-bottom: 60px;

    .container {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        // @media screen and (max-width: $smalltablet) {
        //     max-width: calc(100% - 50px);
        // }
    }
    .close {
        width: 100%;
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;
        button {
            z-index: 99;
            background: url(@/assets/img/svg/hamburger-close-42x42.svg) center
                center no-repeat;
            background-size: 30px auto;
            width: 80px;
            height: 80px;
            top: 0;
            right: 0;
            border: none;
            position: absolute;
            cursor: pointer;
            font-family: $fontDMSans;
        }
        @media screen and (max-width: $mobile) {
            height: 51px;
            button {
                width: 51px;
                height: 51px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        // background: $whiteFE;
        visibility: hidden;

        &.show {
            visibility: visible;
            ul {
                visibility: hidden;
            }
        }
        li {
            margin: 0 15px;
            margin-bottom: 10px;
            padding: 0;
            display: flex;
            justify-content: space-between;
            background: $colorMain;
            &:last-child {
                border: none;
            }
            button {
                @include button;
                display: block;
                position: relative;
                width: 49px;
                border-left: 5px solid $colorWhite;
                background-image: url(@/assets/img/svg/icon-arrow-right.svg);
                background-size: 7px auto;
                background-repeat: no-repeat;
                background-position: right 17px center;
                background-color: transparent;
                padding: 10px;
                cursor: pointer;
                font-family: $fontDMSans;
            }
            .back {
                cursor: pointer;
                font-family: $fontDMSans;
                button {
                    font-family: $fontDMSans;
                    background: url(@/assets/img/svg/icon-arrow-left.svg) left
                        17px center no-repeat;
                    background-size: 7px auto;
                    display: block;
                    background-color: transparent;
                    padding: 15px 15px 15px 40px;
                    border: none;
                    width: 100%;
                    cursor: pointer;
                    @include fontSize16px;
                    text-align: left;
                    // color: $colorBlueDark;
                }
            }
            // & > a.active {
            //     background: linear-gradient(45deg, #6dbe45 0%, #00497b 100%);
            //     font-weight: bold;
            // }
            a {
                padding: 15px 50px 15px 15px;
                position: relative;
                display: block;
                color: $colorWhite;
                @include fontSize16px;
                flex-grow: 1;
            }
            ul {
                top: 0;
                li {
                    a.active {
                        background: none;
                    }
                }
            }
        }
    }
}
</style>
