<template>
    <section v-if="daneDoWyswietlenia" class="full" :style="{ backgroundImage: fullSectionImagePath }">
        <div class="container full-container" :class="{'fade fadeinbottom' : daneDoWyswietlenia[1]}" v-html="daneDoWyswietlenia.content">
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {};
    },
    name: "Full-section",
    props: ["pageData", "daneDoWyswietlenia", "kontakt"],
    computed: {
        fullSectionImagePath() {
            let valToReturn = "none";
            if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
                valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
            }
            return valToReturn;
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.full {
    @include padding125;
    background: $colorWhite;
    // background-size: cover;
    &-container {
        text-align: center;
        h2, p {
            @include fontSize18px;
        }
    }
    header {
        text-align: center;
    }
    .content {
        @include padding50;
    }
}

.contact {
    padding-top: 30px;
    h3 {
        @include fontSize24px;
    }
}

</style>
