<template>
    <footer class="bottom">
        <div class="container bottom-container">
            <div class="row row30">
                <div class="col col4 bottom__box fade fadein fadeinstart">
                    <span>PS-Technic &copy; 2022</span>
                </div>
                <div class="col col4 bottom__box fade fadein fadeinstart">
                        <a
                            :href="`/${pageData.href}`"
                            >RODO</a
                        >
                    <!-- <a href="/rodo/">RODO</a> -->
                </div>
                <div class="col col4 bottom__box bottom__box--news fade fadein fadeinstart">
                    <span v-if="activateLanguage == 'pl'">Realizacja: <a href="https://zdzislowicz.pl" title="Strony WWW">Zdzislowicz.pl</a></span>
                    <span v-else>Realization: <a href="https://zdzislowicz.pl" title="Strony WWW">Zdzislowicz.pl</a></span>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    data() {
        return {
            activateLanguage: this.$root.language,
        };
    },
    name: "Footer-section",
    props: ["pageData"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// @import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

$bigtablet: 1279px;
$tablet: 1023px;
$smalltablet: 767px;
$mobile: 479px;

footer {
    background-color: #000;
    padding: 15px 15px;
    position: relative;
    top: 100px;
    .container {
        display: flex;
        justify-content: space-between;
        z-index: 2;
        a,
        p,
        span {
            @include fontSize13px;
            color: $colorWhite;
            padding: 0;
            text-align: left;
        }
        a {
            transition: 0.2s ease-in color;
            &:hover {
                color: $colorMain;
            }
        }
    }
    .bottom__box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .brand {
            display: flex;
            flex-direction: column;
            gap: 30px;
            picture {
                display: flex;
                justify-content: center;
                width: 100%;
                height: auto;
                position: relative;
                img {
                    width: 100%;
                    max-width: 150px;
                    height: auto;
                }
            }
            @media screen and (max-width: $tablet) {
                flex-direction: row;
                align-items: center;
                & > div {
                    width: 50%;
                }
            }
            @media screen and (max-width: $mobile) {
                flex-direction: column;
                & > div {
                    width: 100%;
                }
            }
        }
        h2 {
            @include fontSize20px;
            text-transform: none;
            font-weight: 600;
        }
        &--categories {
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 0 10px;
                width: 100%;
                @media screen and (max-width: $mobile) {
                    grid-template-columns: 100%;
                }
            }
            @media screen and (max-width: $mobile) {
                display: none;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            text-align: left;
            li {
                a {
                    color: $colorGray;
                    transition: 0.3s ease-in all;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        &--news {
            li {
                display: flex;
                align-items: center;
                &:nth-last-of-type(n + 2) {
                    margin-bottom: 10px;
                }
                span {
                    display: block;
                }
            }
            picture {
                position: relative;
                width: 50px;
                height: 50px;
                min-width: 50px;
                margin-right: 20px;
                overflow: hidden;
                padding: 0;
                border-radius: 50%;
                img {
                    // border-radius: 50%;
                    // width: 50px;
                    // height: 50px;
                    width: auto;
                    height: 100%;
                }
            }
        }
        .address {
            p {
                color: $colorGray;
                a {
                    color: $colorGray;
                }
            }
        }
        &:first-of-type {
            align-items: flex-start;
        }
        &:last-of-type {
            align-items: flex-end;
        }
    }
    @media screen and (max-width: $tablet) {
        top: 70px;
        .bottom__box {
            padding: 3px;
            &:first-of-type {
                align-items: center;
            }
            &:last-of-type {
                align-items: center;
            }
        }
    }
    @media screen and (max-width: $smalltablet) {
        padding: 15px;
        .container {
            flex-direction: column;
            align-items: center;
            gap: 7px;
        }
    }
}
</style>
