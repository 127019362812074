<template>
    <div v-show="allComponentsReady && pageData">
        <h1 style="display: none">PS Technic</h1>
        <Slider :data="sliderData" id="home" class="home"></Slider>
        <Full :daneDoWyswietlenia="wstep"></Full>
        <DoubleHalfDividerWide
            :daneDoWyswietlenia="omnie"
            class="o-mnie"
            data-space="0"
            id="o-mnie"
        ></DoubleHalfDividerWide>
        <Accordion
            class="oferta"
            data-space="0"
            id="oferta"
            :daneDoWyswietlenia="oferta"
            @accordionComponentReadyEvent="
                (value) => {
                    this.$nextTick(() => {
                        accordionComponentReady = value;
                    });
                }
            "
        />
        <Partnerzy />
        <Full
            :daneDoWyswietlenia="kontakt"
            class="contact kontakt"
            data-space="0"
            id="kontakt"
        ></Full>
        <Map />
        <Form></Form>
    </div>
</template>
<script>
import axios from "axios";
import Full from "@/components/Full";
import Slider from "@/components/Slider";
import DoubleHalfDividerWide from "@/components/DoubleHalfDividerWide";
import Accordion from "@/components/Accordion";
import Partnerzy from "@/components/Partnerzy";
import Form from "@/components/Form";
import Map from "@/components/Map";

import EventBus from "@/components/event-bus/event-bus";

export default {
    data() {
        return {
            fullSection: [],
            sliderData: "",
            fullComponentReady: false,
            sliderComponentReady: false,
            accordionComponentReady: false,
            appComponentReady: false,
        };
    },

    components: {
        Full: Full,
        Slider: Slider,
        DoubleHalfDividerWide: DoubleHalfDividerWide,
        Accordion: Accordion,
        Partnerzy: Partnerzy,
        Form: Form,
        Map: Map,
    },
    name: "Glowna-strona",
    props: ["pageData", "stronyZApp"],
    computed: {
        wstep() {
            const content = this.fullSection[0];
            return content;
        },
        omnie() {
            const content = this.fullSection[1];
            return content;
        },
        oferta() {
            const content = this.fullSection[2];
            return content;
        },
        kontakt() {
            const content = this.fullSection[3];
            return content;
        },
        allComponentsReady() {
            const slider = this.sliderComponentReady;
            const full = this.fullComponentReady;
            const accordion = this.accordionComponentReady;
            const app = this.appComponentReady;
            if (slider && full && accordion && app) {
                EventBus.$emit("allComponentsReady", true);
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        getSliderData() {
            axios
                .get("https://ps-technic.pl/api/slider/slider.php")
                .then((response) => {
                    this.sliderData = response.data;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.sliderComponentReady = true;
                        }, 500);
                    });
                });
        },
        getFullSection() {
            this.fullSection = this.stronyZApp;
            this.$nextTick(() => {
                this.fullComponentReady = true;
            });
        },
    },
    created() {
        this.getSliderData();
        this.getFullSection();
    },
    mounted() {
        EventBus.$on("appComponentReadyEvent", () => {
            this.$nextTick(() => {
                this.appComponentReady = true;
            });
        });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.activity {
    background: $colorGray;
    &__header {
        h2 {
            color: $colorWhite;
        }
    }
    &-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
        article {
            display: flex;
            .col6 {
                width: 50%;
                background-size: cover;
                background-position: center;
                min-height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .subheader {
                    @include fontSize14px;
                }
                header {
                    h3 {
                        color: $colorWhite;
                        @include fontSize24px;
                    }
                    span {
                        color: $colorWhite;
                    }
                }
                &.title {
                    border: 20px solid $colorWhite;
                    padding: 40px;
                }
            }
            .links {
                padding: 30px 0 0 0;
                width: fit-content;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 15px;
                a {
                    padding: 10px 20px;
                    border: 1px solid $colorWhite;
                    color: $colorWhite;
                    text-transform: uppercase;
                    transition: ease-in all 0.3s;
                    position: relative;
                    z-index: 1;
                    @include aEffect;
                }
            }
        }
    }
}

.contact {
    @include padding100Bottom;
    p:first-of-type {
        @include fontSize24px;
    }
    a {
        color: $colorMain;
        transition: 0.2s ease-in opacity;
        &:hover {
            opacity: 0.5;
        }
    }
}
</style>
