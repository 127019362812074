<template>
    <div>

    <section class="halfContact" v-if="contact">
        <header class="subpage-header subpage-header--small" :style="{ backgroundImage: fullSectionImagePath }">
            <h1 class="content__header fade fadein fadeinstart">{{ pageData.title }}</h1>
        </header>
        <div class="container halfContact-container">
            <div class="left">
                <header>
                    <h2 class="title fade fadein fadeinstart">Napisz do nas</h2>
                </header>
                <Form></Form>
            </div>
            <div class="right">
                <header>
                    <h2 class="title fade fadein fadeinstart">{{ contact.title }}</h2>
                </header>
                <div class="right__text fade fadeinright fadeinrightstart" v-html="contact.content"></div>
                <!-- <div class="right__text fade fadeinright" v-html="contact.content2"></div> -->
            </div>
        </div>
    </section>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.0810718538805!2d21.980186015682225!3d50.02857667941929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfb22ccaeb859%3A0xdf1ea3b49a12b82f!2sSOGO%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1642750786827!5m2!1spl!2spl"
      width="100%"
      height="450"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
    </div>
</template>
<script>
import axios from "axios";
import Form from "@/components/Form";

export default {
    data() {
        return {
            contact: [],
        };
    },
    name: "Contact-page",
    components: {
        Form: Form,
    },
    props: ["pageData"],
    methods: {
        getContact() {
            axios.get("https://ps-technic.pl/api/contact/contact.php").then((response) => {
                    this.contact = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.getContact();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.subpage-header {
    background: $colorDark;
    text-align: center;
    &--big {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        background-position: bottom;
        background-attachment: fixed;
        background-repeat: no-repeat;
        @media screen and (max-width: $smalltablet) {
            min-height: auto;
        }
    }
    h1 {
        padding: 50px 15px;
        color: $colorWhite;
        @include fontSize45px;
    }
}

.halfContact {
    &-container {
        @include padding50;
        display: flex;
        gap: 40px;
        @media screen and (max-width: $smalltablet) {
            flex-wrap: wrap;
        }
    }
    header {
        h2 {
            @include padding50Bottom;
        }
    }
    .left {
        width: 50%;
        
        @media screen and (max-width: $smalltablet) {
            width: 100%;
        }
    }
    .right {
        width: 50%;
        text-align: left;
        header {
            h3 {
                font-weight: 300;
            }
        }
        @media screen and (max-width: $smalltablet) {
            width: 100%;
        }
    }
}
</style>
