<template>
    <section data-space="0">
        <div class="container">
            <div class="form fade fadein">
                <header>
                    <h2 v-if="activateLanguage == 'pl'">{{ pl.form_tytul }}</h2>
                    <h2 v-else>{{ en.form_tytul }}</h2>
                </header>
                <span class="komunikat potwierdzenie" :class="{ active: potwierdzenie }" style="display: none; text-align: center; color: #0b877a">
                    {{ komunikat }}
                </span>
                <form class="formularz-kontakt" action="#" method="post" :class="{ hide: potwierdzenie }">
                    <div class="flex-box">
                        <fieldset>
                            <legend>Dane</legend>
                            <div class="field">
                                <label for="kontakt_imie" v-if="activateLanguage == 'pl'">{{ pl.label_imie}}</label>
                                <label for="kontakt_imie" v-else>{{ en.label_imie }}</label>
                                <input ref="error_imie" v-on:input="change" required v-model="kontakt_imie" type="text" id="kontakt_imie" class="kontakt_imie" name="kontakt[kontakt_imie]"/>
                                <span class="blad">
                                    {{ formErrors[0].error_imie }}
                                </span>
                            </div>
                            <div class="field">
                                <label for="kontakt_telefon" v-if="activateLanguage == 'pl'">{{ pl.label_telefon }}</label>
                                <label for="kontakt_telefon" v-else>{{ en.label_telefon }}</label>
                                <input ref="error_telefon" v-on:input="change" v-model="kontakt_telefon" type="tel" name="kontakt[kontakt_telefon]" id="kontakt_telefon" class="kontakt_telefon"/>
                                <!-- <span class="blad">
                                    {{ formErrors[0].error_telefon }}
                                </span> -->
                            </div>
                            <div class="field">
                                <label for="kontakt_email" v-if="activateLanguage == 'pl'">{{ pl.label_email }}</label>
                                <label for="kontakt_email" v-else>{{ en.label_email }}</label>
                                <input ref="error_email" v-on:input="change" required v-model="kontakt_email" type="email" name="kontakt[kontakt-email]" id="kontakt_email" class="kontakt_email"/>
                                <span class="blad">
                                    {{ formErrors[0].error_email }}
                                </span>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend>Treść</legend>
                            <div class="field">
                                <label for="kontakt_tresc" v-if="activateLanguage == 'pl'">{{ pl.label_wiadomosc }}</label>
                                <label for="kontakt_tresc" v-else>{{ en.label_wiadomosc }}</label>
                                <textarea ref="error_tresc" v-on:input="change" required v-model="kontakt_tresc" name="kontakt[kontakt_tresc]" id="kontakt_tresc" class="kontakt_tresc"></textarea>
                                <span class="blad">
                                    {{ formErrors[0].error_tresc }}
                                </span>
                            </div>
                        </fieldset>
                        <div class="field agreement">
                            <div
                                class="choice"
                                @click="
                                    kontakt_zgoda = !kontakt_zgoda;
                                    change();
                                "
                            >
                                <div class="checkbox">
                                    <button ref="error_zgoda" class="donotfade check zgoda1" :class="{ active: kontakt_zgoda }" type="button" title="Kliknij, by wyrazić zgodę na przetwarzanie danych"></button>
                                </div>
                                <span class="zgoda" v-if="activateLanguage == 'pl'"
                                    >{{ pl.label_zgoda }}</span
                                >
                                <span class="zgoda" v-else
                                    >{{ en.label_zgoda }}</span
                                >
                                <span class="blad">
                                    {{ formErrors[0].error_zgoda }}
                                </span>
                            </div>
                        </div>
                        <button type="button" class="send" @click="checkForm" v-if="activateLanguage == 'pl'">{{ pl.label_submit }}</button>
                        <button type="button" class="send" @click="checkForm" v-else>{{ en.label_submit }}</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            activateLanguage: this.$root.language,
            kontakt_imie: "",
            kontakt_telefon: "",
            kontakt_email: "",
            kontakt_tresc: "",
            kontakt_zgoda: "",
            komunikat: "",
            potwierdzenie: false,
            formErrors: [
                {
                    error_imie: "",
                    // error_telefon: "",
                    error_email: "",
                    error_tresc: "",
                    error_zgoda: "",
                },
            ],
            pl: 
                {
                    form_tytul: "Formularz kontaktowy",
                    label_imie: "Imię i nazwisko",
                    label_telefon: "Telefon (opcjonalnie)",
                    label_email: "E-mail",
                    label_wiadomosc: "Wiadomość",
                    label_zgoda: "Wyrażam zgodę na przetwarzanie moich danych osobowych, zgodnie z treścią ustawy z dn. 29 sierpnia 1997 r. o ochronie danych osobowych (Dz. U. z 1997 r. Nr 133 poz. 883) w celu właściwej realizacji zapytania. Administratorem danych osobowych jest PS-Technic. Dane osobowe będą przetwarzane w celu właściwej realizacji odpowiedzi na zapytanie.",
                    label_submit: "Wyślij"

                },
            en: 
                {
                    form_tytul: "Contact form",
                    label_imie: "Name",
                    label_telefon: "Phone (optional)",
                    label_email: "E-mail",
                    label_wiadomosc: "Message",
                    label_zgoda: "I use the function of processing my personal data, in accordance with the Act of of August 29, 1997 on the protection of personal data (Dz. U. z 1997 r. Nr 133 poz. 883) for the purpose of initial processing of the inquiry. The administrator of personal data is PS-Technic. Data that will appear in order to answer the query.",
                    label_submit: "Send"

                }
        };
    },
    name: "Form-contact",
    methods: {
        change() {
            if (this.kontakt_imie.length !== 0) {
                this.formErrors[0].error_imie = "";
            }
            // if (this.kontakt_telefon.length !== 0) {
            //     this.formErrors[0].error_telefon = "";
            // }
            if (this.kontakt_email.length !== 0) {
                this.formErrors[0].error_email = "";
            }
            if (this.kontakt_tresc.length !== 0) {
                this.formErrors[0].error_tresc = "";
            }
            if (this.kontakt_zgoda === true) {
                this.formErrors[0].error_zgoda = "";
            }
        },
        checkForm: function (e) {
            e.preventDefault();
            axios
                .post("https://ps-technic.pl/api/form/form.php?jezyk=" +
                        this.activateLanguage, this.pobierzDaneFormularza)
                .then((response) => {
                    var vm = this;

                    this.potwierdzenie = JSON.parse(JSON.stringify(response.data.potwierdzenie));

                    if (this.potwierdzenie === true) {
                        this.komunikat = JSON.parse(JSON.stringify(response.data.komunikat));
                    } else {
                        var errors = JSON.parse(JSON.stringify(response.data.error));

                        var errorFocus = Object.keys(errors)[0];
                        this.$refs[errorFocus].focus();

                        Object.keys(errors).map(function (key) {
                            vm.formErrors[0][key] = errors[key];
                        });
                    }
                })
                .catch((error) => {});
        },
        // submitForm() {},
    },
    computed: {
        pobierzDaneFormularza() {
            const daneZFormularza = {
                imie: this.kontakt_imie,
                telefon: this.kontakt_telefon,
                email: this.kontakt_email,
                tresc: this.kontakt_tresc,
                zgoda: this.kontakt_zgoda,
            };
            return daneZFormularza;
        },
    }    
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

@mixin button {
    font-size: 100%;
    font-family: inherit;
    border: none;
    background: transparent;
    .visuallyhidden {
        display: none;
    }
    &:hover {
        opacity: 0.7;
    }
}

.wide {
    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
    }
    header {
        display: block;
        position: relative;
    }
    p {
        text-align: center;
        &:last-of-type {
            padding-bottom: 0;
        }
    }
}

.komunikat.active {
    display: block !important;
    @include fontSize18px;
  }

.form {
    position: relative;
    @include padding100;
    header {
        h2 {
            color: $text;
            @include padding50Bottom;
        }
    }
    &__header {
        padding: 0 0 40px 0;
        font-weight: 400;
        color: $text;
        @include fontSize18px;
        @media screen and (max-width: $smalltablet) {
            text-align: center;
        }
    }
    .flex-box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px 30px;
        width: 100%;
        color: $text;
        position: relative;

        // @media screen and (max-width: $bigtablet) {
        //     padding: 0 150px;
        // }

        // @media screen and (max-width: $tablet) {
        //     padding: 0 100px;
        // }

        @media screen and (max-width: $smalltablet) {
            grid-template-columns: 100%;
            padding: 0;
            margin: 0 auto;
        }
        @media screen and (max-width: $mobile) {
            grid-gap: 20px;
        }
        fieldset {
            height: 100%;
            position: relative;
            display: grid;
            legend {
                display: none;
            }
        }
        .field {
            width: 100%;
            margin-top: 30px;
            position: relative;
            &:first-of-type {
                margin-top: 0;
            }

            // &--big {

            //     @media screen and (min-width: $smalltablet) {
            //         grid-column-start: 1;
            //         grid-column-end: 2;
            //     }
            // }

            label {
                display: block;
                margin-bottom: 8px;
                @include fontSize16px;
                color: $text;
            }
            span, p {
                &.blad {
                    color: red;
                    // display: none;
                    padding-top: 6px;
                }
            }

            textarea {
                border: 1px solid $colorBorder;
                background-color: $colorWhite;
                padding: 14px 14px 13px;
                width: 100%;
                font-family: $fontDMSans;
                resize: vertical;
                min-height: calc(100% - 28px);
                @include fontSize16px;
                transition: 0.3s ease all;
                overflow: auto;

                &::placeholder {
                    font-family: $fontDMSans;
                    color: #808080;
                }
                @media screen and(max-width: $smalltablet) {
                    padding: 14px 14px 13px;
                    grid-column-end: 1;
                }
                // @media screen and(max-width: $mobile) {
                //     max-width: 248px;
                // }
            }

            input {
                border: 1px solid $colorBorder;
                background-color: $colorWhite;
                // height: 72px;
                // line-height: 70px;
                padding: 14px 14px 13px;
                width: 100%;
                font-family: $fontDMSans;
                @include fontSize16px;

                &::placeholder {
                    font-family: $fontDMSans;
                    color: #808080;
                    font-weight: 400;
                }

            }
            @media screen and (max-width: $mobile) {
                margin-top: 20px;
                input {
                    line-height: 40px;
                    max-height: 50px;
                }
            }
        }
    }
    .agreement {
        grid-column: 1/3;
        @media screen and (max-width: $smalltablet) {
            grid-column-end: 1;
        }
    }
    .choice {
        display: flex;
        // cursor: pointer;
        flex-wrap: wrap;
        .blad {
            width: 100%;
        }
        .checkbox {
            cursor: pointer;
            button {
                cursor: pointer;
                background: $colorWhite;
                display: block;
                height: 27px;
                width: 27px;
                transition: all 0.3s;
                position: relative;
                margin-right: 20px;
                border: 2px solid $text;

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background: url(../assets/img/svg/icon-checked.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 48% auto;
                    transition: 0.3s ease all;
                    opacity: 0;
                }
            }
            .active {
                &::after {
                    opacity: 1;
                }
            }
            input {
                display: none;
            }
        }
        label, span {
            cursor: pointer;
            padding: 0;
            display: block;
            @include fontSize13px;
            width: calc(100% - 50px);
            &.zgoda {
                margin-bottom: 0;
            }

            a {
                text-decoration: underline;
                z-index: 10000;
                position: relative;
                color: $text;
            }
        }
    }

    .send, 
    input[type="submit"] {
        @include button;
        cursor: pointer;
        grid-column: 1/3;
        @include fontSize18px;
        background: $colorMain;
        color: $colorWhite;
        text-align: center;
        height: 80px;
        transition: 0.3s all ease-in;

        @media screen and (max-width: $smalltablet) {
            grid-column-end: 1;
        }

        @media screen and (max-width: $mobile) {
            margin-bottom: 0;
            height: 44px;
            // text-align: center;
        }

        &:hover {
            background-color: $colorMain;
        }
    }
}
</style>
