<template>
    <aside class="map">
        <gmap-map class="map-content"
            :zoom="15"
            :center="center"
            style="width: 100%; height: 600px"
            :options="mapStyle"
        >
            <gmap-marker
                :key="index"
                v-for="(m, index) in locationMarkers"
                :position="m.position"
                :icon="markers"
                :title="markers.title"
                @click="toggleInfoWindow(m, index)"
            >
            </gmap-marker>
            <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                :title="title"
                :logo="logo"
                @closeclick="infoWinOpen = false"
            >
                <div v-html="infoContent"></div>
            </gmap-info-window>
        </gmap-map>
    </aside>
</template>
 
<script>
export default {
    name: "AddGoogleMap",
    data() {
        return {
            center: {
                lat: 50.05932123986521,
                lng: 22.517474561065075,
            },
            infoContent: "",
            infoWindowPos: {
                lat: 0,
                lng: 0,
            },
            infoWinOpen: false,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
            title: '<strong>PS-Technic Piotr Stepaniak</strong>, <br/>ul. Pogodna 22, 37-200 Przeworsk',
            logo: require("../assets/img/svg/small-logo.svg"),
            markers: {
                url: require("../assets/img/svg/marker.svg"),
                scaledSize: { width: 30, height: 45, f: "px", b: "px" },
                // size: {width: 60, height: 90, f: 'px', b: 'px',},
                
            },
            locationMarkers: [
                {
                    position: {
                        lat: 50.05932123986521,
                        lng: 22.517474561065075,
                    },
                },
            ],
            locPlaces: [],
            existingPlace: null,
            mapStyle: {
                styles: [
                    {
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#E6E6E6",
                            },
                        ],
                    },
                    {
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#616161",
                            },
                        ],
                    },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [
                            {
                                color: "#f5f5f5",
                            },
                        ],
                    },
                    {
                        featureType: "administrative.land_parcel",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#bdbdbd",
                            },
                        ],
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#eeeeee",
                            },
                        ],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#757575",
                            },
                        ],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#e5e5e5",
                            },
                        ],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e",
                            },
                        ],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff",
                            },
                        ],
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#757575",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#dadada",
                            },
                        ],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#616161",
                            },
                        ],
                    },
                    {
                        featureType: "road.local",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e",
                            },
                        ],
                    },
                    {
                        featureType: "transit.line",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#e5e5e5",
                            },
                        ],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#eeeeee",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#c9c9c9",
                            },
                        ],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e",
                            },
                        ],
                    },
                ],
            },
        };
    },
    // mounted() {
        // this.locateGeoLocation();
    // },
    methods: {
        toggleInfoWindow: function (markers, idx) {
            this.infoWindowPos = markers.position;
            this.infoContent = this.getInfoWindowContent(markers);

            //check if its the same marker that was selected if yes toggle
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            //if different marker set infowindow to open and reset current marker index
            else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },

        getInfoWindowContent: function () {
            return `
            <div style="padding: 5px;">
                <figure>
                    <img src="${this.logo}" alt="Logo" style="max-width: 50px; margin: 0 auto; margin-bottom: 10px;">
                </figure>
                <p style="text-align: center; padding: 0;">${this.title}</p>
            </div>
            `;
        },

        // initMarker(loc) {
        //     this.existingPlace = loc;
        // },
        // addLocationMarker() {
        //     if (this.existingPlace) {
        //         const marker = {
        //             lat: this.existingPlace.geometry.location.lat(),
        //             lng: this.existingPlace.geometry.location.lng(),
        //         };
        //         this.locationMarkers.push({ position: marker });
        //         this.locPlaces.push(this.existingPlace);
        //         this.center = marker;
        //         this.existingPlace = null;
        //     }
        // },
        // locateGeoLocation: function () {
        //     navigator.geolocation.getCurrentPosition((res) => {
        //         this.center = {
        //             lat: res.coords.latitude,
        //             lng: res.coords.longitude,
        //         };
        //     });
        // },
    },
};
</script>

<style lang="scss" scoped>
.map-content {
    @media screen and (max-width: 767px) {
        max-height: 350px;
    }
}
</style>