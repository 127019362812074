<template>
    <section class="offer" v-if="daneDoWyswietlenia">
        <div
            v-html="daneDoWyswietlenia.content"
            class="offer__content fade fadein"
        ></div>
        <!-- <p class="fade fadein"><strong>Zakres moich usług</strong> opiera się na doświadczeniu w branży i dedykowany jest głównie do firm produkcyjnych potrzebujących uzupełnić swoje chwilowe braki kadrowe, techniczne czy technologiczne jak też do tych, poszukujących stałego i solidnego partnera biznesowego.</p> -->
        <div class="accordion" v-if="accordionSection">
            <div
                class="panel fade fadein"
                v-for="(item, index) in panels"
                :key="index"
            >
                <article data-wcag="1" class="panel-header file">
                    <h2
                        class="fade fadein"
                        :class="{ 'no-content': !item.content }"
                    >
                        {{ item.header }}
                    </h2>
                </article>
                <div
                    class="panel-content transitionmaxheight"
                    style="max-height: 0px"
                >
                    <div
                        class="panel-body"
                        v-if="item.content != ''"
                        v-html="item.content"
                    ></div>
                </div>
            </div>
        </div>
        <!-- <p class="fade fadein ">Prefabrykacja podzespołów do maszyn, urządzeń, drobnych konstrukcji oraz instalacji przemysłowych</p> -->
    </section>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            panels: [],
            activateLanguage: this.$root.language,
            oppositeHrefLanguage: this.$root.oppositeHrefLanguage,
            oppositeNameLanguage: this.$root.oppositeNameLanguage
        };
    },
    name: "Accordion-section",
    props: ["daneDoWyswietlenia"],
    computed: {
        accordionSection() {
            const pages = this.panels;
            const panels = [];
            pages.forEach((item) => {
                const onePanel = {
                    header: item.header,
                    content: item.content,
                };
                panels.push(onePanel);
            });

            return panels;
        },
    },
    methods: {
        getAccordion() {
            axios
                .get(
                    "https://ps-technic.pl/api/accordion/accordion.php?jezyk=" +
                        this.activateLanguage
                )
                .then((response) => {
                    this.panels = response.data;
                    this.$emit("accordionComponentReadyEvent", true);
                });
        },
        showAccordion() {
            setTimeout(function () {
                let panelHeaders = document.querySelectorAll(
                    ".accordion .panel .panel-header"
                );
                if (panelHeaders != null && panelHeaders != "undefined")
                    panelHeaders.forEach(function (item) {
                        item.addEventListener("click", function () {
                            let parentPanelHeader = item.closest(".panel");
                            if (
                                parentPanelHeader.classList.contains("active")
                            ) {
                                parentPanelHeader.classList.remove("active");
                                parentPanelHeader.querySelector(
                                    ".panel-content"
                                ).style.maxHeight =
                                    parentPanelHeader.querySelector(
                                        ".panel-body"
                                    ).offsetHeight + "px";
                                setTimeout(function () {
                                    parentPanelHeader.querySelector(
                                        ".panel-content"
                                    ).style.maxHeight = 0;
                                }, 0);
                            } else {
                                parentPanelHeader.querySelector(
                                    ".panel-content"
                                ).style.maxHeight =
                                    parentPanelHeader.querySelector(
                                        ".panel-body"
                                    ).offsetHeight + "px";
                                parentPanelHeader.classList.add("active");
                            }
                        });
                    });
            }, 1000);
        },
    },
    created() {
        this.getAccordion();
    },
    mounted() {
        this.showAccordion();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.offer {
    background: url(../assets/img/accordion.png);
    background-size: cover;
    background-attachment: fixed;
    @include padding100;
    &__content {
        width: 90%;
        max-width: 1000px;
        margin: 0 auto;
        padding-bottom: 35px;
        p {
            color: $colorWhite;
            text-align: center;
            @include fontSize18px;
            &:last-of-type {
                padding: 0;
            }
        }
        @media screen and (max-width: $smalltablet) {
            padding-bottom: 15px;
        }
    }
}

.accordion {
    padding: 30px 0;
    overflow: hidden;

    .panel {
        margin: 0 auto;
        margin-bottom: 15px;
        overflow: hidden;
        max-width: 770px;
        width: 100%;
        border: 2px solid $colorWhite;
        @media screen and (max-width: $tablet) {
            max-width: 90%;
        }
        // @media screen and (max-width: $smalltablet) {
        //     margin-bottom: 30px;
        // }
        &:last-of-type {
            margin-bottom: 0;
        }
        & > .panel-header {
            z-index: 99;
            h2 {
                color: $colorWhite;
                margin: 0;
                @include fontSize24px;
                padding: 40px 95px 40px 40px;
                font-weight: 400;
                overflow: hidden;
                text-align: left;
                position: relative;
                transition: all 0.3s ease-out;
                line-height: 1;
                &.no-content {
                    cursor: default;
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    background: url(../assets/img/svg/icon-arrow-down-white.svg)
                        center center no-repeat;
                    background-size: 25px auto;
                    width: 25px;
                    height: 100%;
                    right: 40px;
                    top: 0;
                    display: block;
                    z-index: 2;
                    transition: all 0.3s ease-out;
                }

                @media screen and (max-width: $smalltablet) {
                    padding: 20px 55px 20px 20px;
                    &:after {
                        background: url(../assets/img/svg/icon-arrow-down-white.svg)
                            center center no-repeat;
                        background-size: 14px auto;
                        right: 20px;
                    }
                }
                @media screen and (max-width: $mobile) {
                    width: 100%;
                    line-height: 1.2;
                }
            }
        }
        .panel-content,
        .panel2-content {
            .panel-header,
            .panel2-header {
                display: flex;
                flex-direction: column;
                cursor: default;
                width: 100%;
                left: 140px;
                position: relative;
                &.brochure {
                    align-items: center;
                    left: 0;
                }
                @media screen and (max-width: $bigtablet) {
                    left: 0;
                }
            }
            ul,
            ol {
                margin: 0 auto;
                padding-top: 0;
                padding-left: 17px;
                li {
                    font-weight: 400;
                }
            }
        }
        .panel-content,
        .panel2-content {
            overflow: hidden;
            max-height: 0;
            width: 100%;
            color: $colorWhite;
            p,
            ul,
            li {
                color: $colorWhite;
            }
            .panel-body,
            .panel2-body {
                @include fontSize16px;
                font-weight: 300;
                width: 100%;
                padding: 0 40px 40px 40px;
                @media screen and (max-width: $smalltablet) {
                    padding: 0 20px 20px 20px;
                }

                & > .active {
                    .panel-header,
                    .panel2-header {
                        h3 {
                            &:after {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }
            p {
                display: block;
                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }
        .transitionmaxheight {
            transition: max-height 0.3s ease-out;
        }
    }
    & > .active {
        .panel-header,
        .panel2-header {
            h2 {
                &:after {
                    transform: rotate(-180deg);
                }
            }
        }
    }
    ol {
        padding-top: 40px;
    }
}
</style>
