<template>
    <div v-if="pageData" class="textpage">
        <header class="subpage-header subpage-header--small">
            <h1 class="content__header">{{ pageData.title }}</h1>
        </header>
        <section class="full">
        <div class="container full-container" v-html="pageData.content">
        </div>
        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facilis asperiores consectetur enim totam et optio, expedita, inventore sed quos debitis ratione minima, tempore accusamus nobis eius eos reprehenderit fugiat.</div>
        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facilis asperiores consectetur enim totam et optio, expedita, inventore sed quos debitis ratione minima, tempore accusamus nobis eius eos reprehenderit fugiat.</div>
        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facilis asperiores consectetur enim totam et optio, expedita, inventore sed quos debitis ratione minima, tempore accusamus nobis eius eos reprehenderit fugiat.</div>
        <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus facilis asperiores consectetur enim totam et optio, expedita, inventore sed quos debitis ratione minima, tempore accusamus nobis eius eos reprehenderit fugiat.</div>
    </section>
    </div>
</template>

<script>
// import axios from "axios";
// import Full from "@/components/Full";

export default {
    data() {
        return {
            // textPages: null,
            // galleryItems: null
        };
    },
    components: {
        // Full: Full,
    },
    name: "Tekstowa-strona",
    props: ["pageData", "stronyZApp"],
    computed: {
        // fullSectionImagePath() {
        //     let valToReturn = "none";
        //     if (this.daneDoWyswietlenia && this.daneDoWyswietlenia.imagePath) {
        //         valToReturn = `url(${this.daneDoWyswietlenia.imagePath})`;
        //     }
        //     return valToReturn;
        // },
        // rodo() {
        //     const content = this.fullSection[11];
        //     return content;
        // },
    },
    methods: {
        // getTextPages() {
        //     // axios.get("/api/textPages/textPages.php").then((response) => {
        //     axios.get("https://ps-technic.pl/api/textPages/textPages.php").then((response) => {
        //     //axios.get("http://localhost:3000/textPages").then((response) => {
        //         this.textPages = response.data;
        //     });
        // },
        // getFullSection() {
        //     this.fullSection = this.stronyZApp;
        // },
    },
    mounted() {
        // this.getTextPages();
        // this.getFullSection();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";

.textpage {
    @include padding100Bottom;
    .textContent {
        .container {
            // picture {
                // width: 100%;
                // height: auto !important;
                // margin-bottom: 50px;
                // img {
                    // width: 100%;
                    // height: auto;
                // }
            // }
           
            img {
                // width: 100%;
                height: auto !important;
                // margin: 15px 0;
            }
            p a img {
                margin: 0 auto;
            }
        }
        video {
            @media screen and (max-width: $tablet) {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
</style>
