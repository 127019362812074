import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
// import Paginate from "vuejs-paginate";
import VueNumber from "vue-number-animation";
import checkView from "vue-check-view";
// import VueI18n from "vue-i18n";
import * as VueGoogleMaps from "vue2-google-maps" // Import package
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
// AOS.init();

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB6MHNcTulmaucsUWXFlRjyF0C3jz0JwcU",
        libraries: "places"
    }
});
Vue.use(checkView);
Vue.use(VueNumber);
// Vue.use(VueI18n);

Vue.config.productionTip = false;

var VueCookie = require("vue-cookie");
Vue.use(VueCookie);

Vue.mixin({ 
    data: function() {
        return {
            language: "pl",
            oppositeHrefLanguage: "en",
            oppositeNameLanguage: "en",
        }
    }
});

window.$ = window.jQuery = require("jquery");

new Vue({
  router,
  // i18n,
  render: (h) => h(App),
}).$mount("#app");
