<template>
    <aside class="partners">
        <div class="container">
            <div class="partners-partners">
                <ul class="fade fadein">
                    <li v-for="(partner, index) in partnerzy" :key="index">
                        <a v-if="partner.href != ''" :href="partner.href" target="_blank" rel="nofollow"><picture><img :src="partner.imagePath" :alt="partner.alt" /></picture></a>
                        <picture v-else><img :src="partner.imagePath" :alt="partner.alt" /></picture>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            partnerzy: [],
        };
    },
    computed: {
        
    },
    name: "Partnerzy-sekcja",
    methods: {
        getBanery() {
            axios.get("https://ps-technic.pl/api/banery/banery.php").then((response) => {
                this.partnerzy = response.data.partnerzy;
            });
        },
    },
    mounted() {
        this.getBanery();
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/css/zdzislowicz-2.13.scss";
@import "@/assets/css/mixins.scss";
.partners {
    @include padding100Top;
    header {
        h2 {
            @include fontSize24px;
        }
    }
    &-support {
        @include padding80Top;
    }
    ul {
        padding: 0;
        margin: 0 auto;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;
        max-width: 835px;
        li {
            position: relative;
            display: flex;
            align-items: center;
            a {
                display: block;
                width: 100%;
                height: auto;
                top: 0;
                left: 0;
                transition: 0.2s ease-in opacity;
                &:hover {
                    opacity: 0.7;
                }
            }
            picture {
                width: 100%;
                height: auto;
                max-width: 80px;
                position: relative;
                img {
                    // max-width: 260px;
                    // max-height: 64px;
                    width: 100%;
                    height: auto;
                }
            }
            &:nth-child(1),
            &:nth-child(3) {
                max-width: 55px;
            }
        }
        @media screen and (max-width: $smalltablet) {
            li {
                max-width: calc(33% - 40px);
                picture {
                    img {
                        max-width: unset;
                        max-height: unset;
                    }
                }
            }
        }
        @media screen and (max-width: $mobile) {
            gap: 20px;
            justify-content: space-evenly;
            li {
                max-width: calc(33% - 20px);
            }
        }
    }
}
</style>
